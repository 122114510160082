<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="cart-item-container row">
      <div class="thumb-image col-1" v-bind:style="{'background-image': 'url(/storage/' + product.image + ')'}"></div>
      <div class="col-9 col-md-10">
        <div class="d-md-flex ">
          <div class="cart-item-title">
            <router-link :to="{name: 'SingleProductPage', params: {id: product.id}}">
              {{ product.title }}
            </router-link>
          </div>
          <div class="icon-container ps-2 ms-auto d-md-flex d-none">
            <div class="pe-2">
              <AddToFavourites :product="{
                id: product.id,
                image: product.image,
                labeldiscount: parseFloat(product.promoPrice) > 0,
                title: product.title,
                category: product.categories[0].title,
                labelnew: false,
                regularamount: product.price,
                discountedamount: product.promoPrice,
            }"/>
            </div>
            <div class="cursor-pointer">
              <xIcon  @click="removeItemFromCart(product.id)"/>
            </div>

          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="plus-minus-input me-auto">
            <PlusMinusInput v-model="product.quantity"/>
            <span class="ps-2">{{ $t('quantity') }}</span>
          </div>
          <div class="icon-container d-flex d-md-none align-items-center">
              <AddToFavourites class="p-2" :product="{
                id: product.id,
                image: product.image,
                labeldiscount: parseFloat(product.promoPrice) > 0,
                title: product.title,
                category: product.categories[0].title,
                labelnew: false,
                regularamount: product.price,
                discountedamount: product.promoPrice,
            }"/>
            <xIcon  @click="removeItemFromCart(product.id)"/>
          </div>
          <div class="d-md-flex d-none">
            <div class="price-container ">
              <div class="regular-price" v-if="parseFloat(product.promoPrice) > 0">
                <div class="price-amount">
                  {{ parseFloat(product.price).toFixed(2) }} {{ $t('currency') }}
                </div>
              </div>
              <div class="discounted-price">
                <div class="price-amount" v-if="parseFloat(product.promoPrice) > 0">
                  {{ parseFloat(product.promoPrice).toFixed(2) }} {{ $t('currency') }}
                </div>
                <div v-else>
                  {{ parseFloat(product.price).toFixed(2) }} {{ $t('currency') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="price-container d-md-none">
        <div class="regular-price" v-if="product.promoPrice">
          <div class="price-amount">
            {{ product.price }}{{ $t('currency') }}
          </div>
        </div>
        <div class="discounted-price">
          <div class="price-amount">
            {{ parseFloat(product.promoPrice) > 0 ? product.promoPrice : product.price }}{{ $t('currency') }}
          </div>
        </div>
      </div>

    </div>


  </div>
</template>
